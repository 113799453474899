import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Feedback } from '../types/feedback.types';
import { AnyModelStr } from '../types/model.types';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  public getAllFeedbacks(id: string, role_type: AnyModelStr) {
    return this.http.get<Feedback[]>(environment.apiUrl + `/${role_type}/feedback/${id}/`);
  }

  public getFeedbackDetail(id: string) {
    return this.http.get<Feedback>(environment.apiUrl + `/feedback/find/${id}/`);
  }

  public updateFeedback(feedback: Feedback) {
    return this.http.post<Feedback>(environment.apiUrl + `/feedback/update/${feedback.id}/`, {
      updated_feedback: feedback,
    });
  }

  public createFeedback(feedback: Feedback) {
    return this.http.post<Feedback>(environment.apiUrl + `/feedbacks/`, { feedback });
  }
}
