import { Injectable } from '@angular/core';

/**
 * Service for managing loading state in components & waiting for BE requests.
 * This service provides methods to get and set the loading state.
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor() {}

  /**
   * Boolean flag indicating if the application is currently in a loading state.
   */
  isLoading: boolean = false;

  /**
   * Get the current loading state.
   * @returns {boolean} The current loading state, `true` if loading, `false` otherwise.
   */
  getLoading(): boolean {
    return this.isLoading;
  }

  /**
   * Set the loading state.
   * @param {boolean} value - The loading state to set. `true` to indicate loading, `false` otherwise.
   */
  setLoading(value: boolean) {
    this.isLoading = value;
  }
}
