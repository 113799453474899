import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { environment } from 'src/environments/environment';
import { Mentor } from '../types/mentor.types';
import { MenteeRelation } from '../types/mentee-relations.types';

@Injectable({
  providedIn: 'root',
})
export class MenteeService {
  constructor(private http: HttpClient) {}

  public getMenteeOfUser() {
    return this.http.get<Mentee>(environment.apiUrl + '/mentees/of_user/');
  }

  public updateMentee(menteeId: string, updatedMentee: Partial<Mentee>) {
    return this.http.patch<Mentee>(environment.apiUrl + '/mentees/' + menteeId + '/', updatedMentee);
  }

  public getSuggestedMentors() {
    return this.http.get<Mentor[]>(environment.apiUrl + '/mentees/suggested_mentors/');
  }

  public postExtendReason(reason: string, relation: MenteeRelation) {
    return this.http.post<Mentee[]>(environment.apiUrl + '/mentees/extend_programme/', { reason, relation });
  }
}
